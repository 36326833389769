<template>
<div class="">
  <Navbar></Navbar>
  <v-container grid-list-xl>
    <v-layout  wrap>
      <v-card width="100%">
       <v-card-title>
         My Visits
         <v-spacer></v-spacer>
         <v-text-field
           v-model="search"
           append-icon="search"
           :label="$t('tenants.search')"
           single-line
           hide-details
         ></v-text-field>
       </v-card-title>
       <v-card-text>
          <v-layout px-5  wrap>
            These are the visit requests that you made. They will disappear within 48h if the landlord did not confirm a time and date. Please note that the room WILL NOT be reserved for you until then. Someone else might book the room before your visit. The landlord will give the room to the first person who pays.
          </v-layout>
       <v-data-table
         :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
         :headers="headers"
         :items="visits"
         :search="search"
         :loading="initialLoad"
       >
        <v-progress-linear slot="progress" color="primary" indeterminate ></v-progress-linear>

         <template slot="items" slot-scope="props">
           <td><a :href="props.item.roomLink" target="_blank">Room:{{ props.item.roomNumber}}</a></td>
           <td>{{props.item.status}}</td>
           <td><span v-if="props.item.status!='Requested'">{{props.item.date | dateFormat}} at {{props.item.time}}</span><span v-else>Waiting for landlord confirmation</span> </td>
           <td><a :href="'https://maps.google.com/?q='+props.item.location.lat+','+props.item.location.lng">Google maps</a></td>
           <td>{{props.item.timeslot}}</td>

         </template>
         <v-alert slot="no-results" :value="true" icon="warning">
           {{$t('tenats.noResults',{search: search})}}
         </v-alert>
       </v-data-table>
      </v-card-text>
     </v-card>
   </v-layout>
  </v-container>
</div>
</template>
<script>
import Loading from '../shared/Loading.vue'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'

export default {
  name: "",
  data: () => ({
    search: null,
    searched: [],
    initialLoad:true,

  }),

  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    headers(){
      let t=[
        { text: "Room", value: 'roomNumber' },
        { text: "Status", value: 'status' },
        { text: "Appointment", value: 'confDate' },
        { text: "Location", value: 'location'},
        { text: "Your timeslots", value: 'timeslot' },
      ]
       return t
    }

  },
  firebase() {
    return {
      visits: {
        source:firebase.database().ref('visits').orderByChild("tenantId").equalTo(this.user.id),
        readyCallback: function() {
          for (var i = 0; i < this.visits.length; i++) {
            if (moment(this.visits[i].dateRequested).add(2,'days').isBefore(moment()) && this.visits[i].status=='Requested'){
              this.visits[i].status="Expired"
              axios.patch('visits/'+this.visits[i].key+'.json?auth='+this.$store.state.auth.idToken,{status:'Expired'})
              axios.patch('users/'+this.user.id+'.json?auth='+this.$store.state.auth.idToken,{visits:this.users.visits+1})
            }
            switch(this.visits[i].type.toLowerCase()){
              case 'room':
                this.visits[i].roomLink="/details/"+this.visits[i]['listingId']
                break;
              case 'studio':
                this.visits[i].roomLink="/studioDetails/"+this.visits[i]['listingId']
                break
              case 'apartment':
                this.visits[i].roomLink="/apartmentDetails/"+this.visits[i]['listingId'+'/apartment']
                break
              default:
                this.visits[i].roomLink=""
            }
          }
          this.initialLoad=false
        },
      },
    }
  },
  methods:{
  },
  created() {
  },
  filters:{
    dateFormat: function(t) {
      return moment(t).format('ll')
    },


  },
  watch: {
  },
  components: {
    Loading,

  }

}
</script>
<style lang="scss" scoped>
.landlordDashboardContainer {
    min-height: 100vh;
}
.container {
    width: 100vw;
    max-width: 1200px;
}

.pa-3.top-padding-only {
    padding-bottom: 0px!important;
}
</style>
